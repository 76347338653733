<template>
  <el-dialog title="活动" top="5vh" :before-close="handleClose" :close-on-click-modal="false" append-to-body :visible.sync="dialogVisible" width="900px">
    <div style="margin-bottom: 16px; display: flex; align-items: center;">
      <el-input style="width: 200px;margin-right: 16px;" v-model="pageParams.activity_name" placeholder="请输入活动名称" @keyup.enter.native="onSearch" clearable></el-input>
      <el-button type="primary" @click="onSearch">查询 </el-button>
    </div>
    <el-table
        ref="tableData"
        :data="tableData"
        :header-cell-style="{ background: '#F8F9FC', color: '#333333' }"
        height="600"
      >
        <template slot="empty"><el-empty :description="'暂无数据'"></el-empty></template>
        <el-table-column :label="'活动名称'" prop="activity_name" width="150">
          <template slot-scope="scope">
            <el-radio v-model="activity_id" :label="scope.row.promotion_activity_id">{{ scope.row.activity_name }}</el-radio></template>
        </el-table-column>
        <el-table-column :label="'活动时间'" width="180"> 
          <template slot-scope="scope">
            <span>{{ scope.row.activity_start_time + '~' + scope.row.activity_end_time }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="'活动状态'">
          <template slot-scope="scope">
            <span>{{ filterStatus(scope.row.activity_status) }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="'报名人数'">
          <template slot-scope="scope">
            <span>{{ scope.row.sign_up_count || 0 }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="'参与人数'">
          <template slot-scope="scope">
            <span>{{ scope.row.participation_count || 0 }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="'分享量'">
          <template slot-scope="scope">
            <span>{{ scope.row.share_count || 0 }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="'浏览量'">
          <template slot-scope="scope">
            <span>{{ scope.row.browse_count || 0 }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageParams.page_no"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageParams.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
  </el-dialog>
</template>

<script>
import { getActivityList } from '@/api/shop'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tableData: [],
      total: 0,
      pageParams: {
        page_no: 1,
        page_size: 10,
        activity_name: ''
      },
      activity_id:''
    }
  },
  mounted() {
    this.getActivityListFn()
  },
  computed: {
    filterStatus() {
      return val => {
        switch (Number(val)) {
          case 1:
            return '未开始'
          case 2:
            return '进行中'
          case 3:
            return '已结束'
          default:
            return '未知'
        }
      }
    },
  },
  methods: {
    onSearch() {},
    async getActivityListFn() {
      const res = await getActivityList(this.pageParams)
      this.tableData = res.data
      this.total = res.data_total
    },
    // 点击页容量
    handleSizeChange(size) {
      this.pageParams.page_no = 1
      this.pageParams.page_size = size
      this.getActivityListFn()
    },
    // 点击页码查询
    handleCurrentChange(page) {
      this.pageParams.page_no = page
      this.getActivityListFn()
    },
    handleClose() {
      this.emitClose(false, {});
      this.$emit('update:dialogVisible', false)
    },
    handleSubmit() {
      if (this.activity_id == "") {
        return this.$message.info("请选择一个活动");
      }
      const obj = this.findCurrentItem(this.activity_id);
      let params = {
        type:"activity",
        label:obj.activity_name,
        activity_id:obj.activity_id,
        value:`/pages/retail/activity/index?activity_id=${this.activity_id}`
      }
      this.emitClose(true, params);
      this.handleClose();
    },
    emitClose(isRefsh, params) {
      this.$emit("submit", { params, isRefsh });
    },
    findCurrentItem(id) {
      let obj = {};
        const index = this.tableData.findIndex(
          (item) => item.promotion_activity_id === id
        );
        obj = this.tableData[index];
      return obj;
    },
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  text-align: right;
  margin-top: 16px;
}
</style>

