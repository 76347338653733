var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-container",class:{ 'is-pc': _vm.$$IsPC }},[_c('div',{staticClass:"app-header"},[_c('el-button',{on:{"click":function($event){_vm.is_preview = !_vm.is_preview}}},[_vm._v(_vm._s(_vm.is_preview ? "取消预览" : "预览"))]),_c('el-button',{attrs:{"type":"primary","disabled":_vm.is_preview},on:{"click":_vm.handleSave}},[_vm._v("保存")])],1),_c('div',{staticClass:"app-body"},[(_vm.modules.length > 0)?_c('draggable',_vm._b({staticClass:"label-box",attrs:{"list":_vm.modules,"clone":_vm.handleClonePreview,"filter":".disabled"}},'draggable',_vm.labelDragOption,false),[_vm._l((_vm.modules),function(item){return [(item.label.show !== false)?_c('div',{key:item.name,class:{
            disabled:
              item.label.limit !== -1 &&
              _vm.computePreviewNum(item.name) >= item.label.limit,
          },on:{"click":function($event){return _vm.handleClickLabel(item)}}},[_c('div',{staticClass:"item-label"},[_c('i',{staticClass:"icon-label",style:({ backgroundImage: `url(${item.label.icon})` })}),_c('p',{staticClass:"title-label"},[_vm._v(_vm._s(item.label.title))]),_c('p',{staticClass:"limit-label"},[_vm._v(" "+_vm._s(_vm.computePreviewNum(item.name))+"/"+_vm._s(item.label.limit === -1 ? "无限制" : item.label.limit)+" ")])])]):_vm._e()]})],2):_c('div',{staticClass:"label-box__empty"},[_c('img',{staticClass:"label-box__empty-img",attrs:{"src":require("../assets/placeholder-module.png"),"alt":"没有模块"}}),_c('p',{staticClass:"label-box__empty-tip"},[_vm._v("没有模块...")])]),_c('div',{staticClass:"preview-box"},[_c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.is_preview),expression:"is_preview"}],staticClass:"preview-mask"})]),_c('div',{staticClass:"preview-inner",class:{ 'is-preview': _vm.is_preview }},[(_vm.$$IsMobile)?_c('div',{staticClass:"preview-header",on:{"click":_vm.handleClickPreviewHeader}},[_c('div',{staticClass:"title-preview"},[_vm._v(" "+_vm._s(_vm.$$AccessoriesData["page-params"].page_name)+" ")])]):_vm._e(),_c('draggable',_vm._b({staticClass:"preview-body",attrs:{"disabled":_vm.is_preview,"group":"previews"},on:{"add":_vm.handleDraggableAdd,"end":_vm.handleDragEnd},model:{value:(_vm.previews),callback:function ($$v) {_vm.previews=$$v},expression:"previews"}},'draggable',_vm.previewDragOption,false),[(_vm.$$AccessoriesData['page-params'].background_image)?_c('div',{staticClass:"header-bg",style:({
              backgroundImage: `url(${_vm.$$AccessoriesData['page-params'].background_image.src})`,
              backgroundSize: 'cover',
              height: `${_vm.$$AccessoriesData['page-params'].background_height}px`,
            })}):_vm._e(),_vm._l((_vm.previews),function(item,index){return _c('div',{key:item.__key,staticClass:"item-preview",class:{ activated: _vm.activated === item.__key }},[_c('div',{staticClass:"item-preview__wrapper",on:{"click":function($event){return _vm.handleClickPreview(item, index)}}},[_c('el-popover',{attrs:{"placement":_vm.$$IsMobile ? 'right' : 'top',"trigger":"hover","open-delay":_vm.$$IsMobile ? 0 : 300,"append-to-body":false}},[_c('div',{staticClass:"item-preview__content",attrs:{"slot":"reference"},slot:"reference"},[_c(`${item.name}-preview`,{key:`preview-${item.__key}`,ref:`preview_${item.__key}`,refInFor:true,tag:"component",staticClass:"item-preview__inner",attrs:{"preview":item}}),_c('div',{staticClass:"item-preview__mask"})],1),_c('div',{staticClass:"action-preview"},[_c('el-button',{attrs:{"type":"danger","size":"mini"},on:{"click":function($event){return _vm.handleDeletePreview(item, index)}}},[_vm._v("删除")]),_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleCopyPreview(item, index)}}},[_vm._v("复制")])],1)])],1)])})],2)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.is_preview),expression:"!is_preview"}],staticClass:"accessories-box"},_vm._l((_vm.accessories),function(item){return _c('div',{key:item.name,staticClass:"item-accessory",class:{ 
            activated: _vm.$$ActivatedPreview && _vm.$$ActivatedPreview.__key === item.__key 
          },on:{"click":function($event){return _vm.handleClickAccessory(item)}}},[_c('i',{staticClass:"icon-accessory",style:({ backgroundImage: `url(${item.label.icon})` })}),_c('span',[_vm._v(_vm._s(item.label.title))])])}),0)],1),_c('div',{staticClass:"settings-box"},[(_vm.$$ActivatedPreview)?_c('div',{staticClass:"header-settings"},[_c('span',[_vm._v(_vm._s(_vm.$$ActivatedPreview.label.title))])]):_vm._e(),(_vm.$$ActivatedPreview)?_c('el-form',{attrs:{"model":_vm.$$FormData},nativeOn:{"submit":function($event){$event.preventDefault();}}},[_vm._l((_vm.activatedSettingItems),function(item){return _c('div',{key:item.__key},[(_vm.formItemShow(item))?_c('el-form-item',{class:{
              'has-space': item.space_line,
              'form-item__column': item.column,
            },attrs:{"prop":item.__key}},[_c(`setting-${item.type}`,{key:_vm.$$ActivatedPreview.__key + '|' + item.__key,tag:"component",attrs:{"config":item,"preview":_vm.$$ActivatedPreview}}),(item.space_line)?_c('div',{staticClass:"form-item-line"}):_vm._e()],1):_vm._e()],1)}),(_vm.$$ActivatedPreview && _vm.$$ActivatedPreview.has_setting)?_c(`${_vm.$$ActivatedPreview.name}-setting`,{key:`setting-${_vm.$$ActivatedPreview.__key}`,tag:"component",attrs:{"preview":_vm.$$ActivatedPreview}}):_vm._e()],2):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }