<template>
  <div class="image-selector image-selector__upload">
    <el-upload
      list-type="picture-card"
      :action="$$UploadApi"
      :http-request="$$UploadHttpRequest"
      :on-success="handleUploadSuccess"
      :on-remove="handleUploadRemove"
      :file-list="fileList"
      :before-upload="beforeUpload"
      :accept="$$UploadAccept('image')"
      :limit="1"
    >
      <i class="el-icon-plus"></i>
      <p class="image-selector__tip">添加图片</p>
    </el-upload>
  </div>
</template>

<script>
import { zipImg } from '@/utils/zipImg'
export default {
  name: 'ImageSelector',
  props: {
    value: {
      type: [Object, String],
      required: true
    }
  },
  data() {
    return {
      image: '',
      show_resource_picker: false,
      fileList: []
    }
  },
  model: { event: 'update', prop: 'value' },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.image = newVal
        this.fileList = (newVal && newVal.src)
          ? [{ name: '图片', url: newVal.src }]
          : []
      }
    }
  },
  methods: {
    /**
     * 上传图片
     */
    handleUploadImage() {
      this.show_resource_picker = true
    },
    /**
     * 素材选择器确认选择
     * @param items
     */
    handleResourcePickerConfirm(items) {
      if (items) {
        this.image = { src: items[0]['resource_url'] }
      } else {
        this.image = ''
      }
      this.$emit('update', this.image)
    },
    /**
     * 图片上传成功
     * @param res
     */
    handleUploadSuccess(res) {
      this.image = { src: res.url||res.data.url }
      this.$emit('update', this.image)
    },
    /**
     * 移除图片
     */
    handleUploadRemove() {
      this.image = ''
      this.$emit('update', this.image)
    },
     // 文件上传检验
   async beforeUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      // const isLt1M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error(this.$t('上传图片只能是 PNG、JPEG、JPG 格式'))
        return Promise.reject(false)
      }
      const compressedFile = await zipImg(file)
      return compressedFile
      // if (!isLt1M) {
      //   this.$message.error(this.$t('上传图片大小不能超过5MB'))
      //   return Promise.reject(false)
      // }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "./styles";
</style>
