import request from "@/utils/request";
import { api } from "~/ui-domain";
/**
 * 获取店铺详情
 * @param shop_id
 * @returns {AxiosPromise<any>|Promise<unknown>|*}
 */
export function getShopDetail(shop_id) {
  return request({
    url: `${api.buyer}/shops/${shop_id}`,
    method: "get",
    loading: false,
  });
}

// 获取商品分类
export function getProductTree(params) {
  return request({
    url: `/shop/mallCategory/query/tree`,
    method: "get",
    loading: false,
    params,
  });
}

// 获取产品列表
export function getProductList(params) {
  return request({
    url: "/goods/product/query/findSearchPage",
    method: "get",
    loading: true,
    params,
  });
}
/**
 * 商城项目列表
 * @param params 参数
 */
export function findPageByMall(params) {
  return request({
    url: "shop/project/query/findPageByMall",
    method: "get",
    loading: true,
    params,
  });
}

/* 活动列表数据 */
export function getActivityList(params) {
  return request({
    url: '/distribution/promotionActivity/query/findSearchPage',
    method: 'get',
    loading: false,
    params
  })
}

/* 微页面列表数据 */
export function getMobilePageList(params) {
  return request({
    url: '/shop/pages/get',
    method: 'get',
    loading: false,
    params
  })
}