<template>
  <el-dialog title="微页面" top="5vh" :before-close="handleClose" :close-on-click-modal="false" append-to-body :visible.sync="dialogVisible" width="900px">
    <div style="margin-bottom: 16px; display: flex; align-items: center;">
      <el-input style="width: 200px;margin-right: 16px;" v-model="pageParams.keyword" placeholder="请输入名称" @keyup.enter.native="onSearch" clearable></el-input>
      <el-button type="primary" @click="onSearch">查询 </el-button>
    </div>
    <el-table
        ref="tableData"
        :data="tableData"
        :header-cell-style="{ background: '#F8F9FC', color: '#333333' }"
        height="600"
      >
        <template slot="empty"><el-empty :description="'暂无数据'"></el-empty></template>
        <el-table-column :label="'页面名称'" prop="page_name" width="150">
          <template slot-scope="scope">
            <el-radio v-model="id" :label="scope.row.id">{{ scope.row.page_name }}</el-radio></template>
        </el-table-column>
        <el-table-column :label="'发布状态'">
          <template slot-scope="scope">
            <span> {{ scope.row.publish_status === 'YES' ? '已发布' : '未发布' }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="'创建时间'">
          <template slot-scope="scope">
            <span>{{ scope.row.create_time  }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="'页面描述'" prop="remark"></el-table-column>
      </el-table>
      <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageParams.page_no"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageParams.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
  </el-dialog>
</template>

<script>
import { getMobilePageList } from '@/api/shop'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tableData: [],
      total: 0,
      pageParams: {
        client_type: 'MOBILE',
        keyword: '',
        publish_status: '',
        is_special_index: 'NO',
        page_no: 1,
        page_size: 10
      },
      id:''
    }
  },
  mounted() {
    this.gePageListFn()
  },
  computed: {
    filterStatus() {
      return val => {
        switch (Number(val)) {
          case 1:
            return '未开始'
          case 2:
            return '进行中'
          case 3:
            return '已结束'
          default:
            return '未知'
        }
      }
    },
  },
  methods: {
    onSearch() {},
    async gePageListFn() {
      const res = await getMobilePageList(this.pageParams)
      this.tableData = res.data
      this.total = res.data_total
    },
    // 点击页容量
    handleSizeChange(size) {
      this.pageParams.page_no = 1
      this.pageParams.page_size = size
      this.gePageListFn()
    },
    // 点击页码查询
    handleCurrentChange(page) {
      this.pageParams.page_no = page
      this.gePageListFn()
    },
    handleClose() {
      this.emitClose(false, {});
      this.$emit('update:dialogVisible', false)
    },
    handleSubmit() {
      if (this.id == "") {
        return this.$message.info("请选择一个页面");
      }
      const obj = this.findCurrentItem(this.id);
      let params = {
        type:"activity",
        label:obj.page_name,
        id:obj.id,
        value:`/pages/search/decorPage/index?id=${this.id}`
      }
      this.emitClose(true, params);
      this.handleClose();
    },
    emitClose(isRefsh, params) {
      this.$emit("submit", { params, isRefsh });
    },
    findCurrentItem(id) {
      let obj = {};
        const index = this.tableData.findIndex(
          (item) => item.id === id
        );
        obj = this.tableData[index];
      return obj;
    },
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  text-align: right;
  margin-top: 16px;
}
</style>

